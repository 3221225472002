import {
	getLocationsForMap,
	getEvents,
	getEventDetails,
	readedNotification,
} from '@/repositories/v2/dashboard';
import Vue from 'vue';

export default {
	namespaced: true,
	state: {
		eventFromNotificationChannel: {},
		locationImportChannel: {},
		userImportChannel: {},
		locations: [],
		events: [],
		calls: [],

		supportData: {
			locations: {},
			users: {},
			tasks: {},
			statuses: {},
			types: {},
		},

		// This holds full data for a task performance.
		// Putting it here allows us to get updates from the WebSocket
		// that come in while the popup is open.
		detailedEvents: null,
	},
	mutations: {
		LOAD_LOCATIONS: (state, locations) => {
			state.locations = locations.data;
		},
		LOAD_EVENTS: (state, { locations, users, tasks, events, statuses, types }) => {
			locations.forEach(it => Vue.set(state.supportData.locations, it.id, it));
			users.forEach(it => Vue.set(state.supportData.users, it.id, it));
			tasks.forEach(it => Vue.set(state.supportData.tasks, it.id, it));
			statuses.forEach(it => Vue.set(state.supportData.statuses, it.id, it));
			types.forEach(it => Vue.set(state.supportData.types, it.id, it));
			state.events = events;
		},
		LOAD_EVENT_DETAILS: (state, details) => {
			state.detailedEvents = details;
		},
		CLEAR_EVENT_DETAILS: (state) => {
			state.detailedEvents = null;
		},
		LOCATION_IMPORT_STATUS: (state, event) => {
			state.locationImportChannel = event;
		},
		USER_IMPORT_STATUS: (state, event) => {
			state.userImportChannel = event;
		},
		ADD_NOTIFICATION: (state, { event, user }) => {
			state.eventFromNotificationChannel = event;
			// Vue.set(state.supportData.locations, location.id, location);
			// if (user) {
			// 	Vue.set(state.supportData.users, user.id, user);
			// }
			// Vue.set(state.supportData.tasks, task.id, task);

			// event.location = location;
			// event.task = task;
			// event.user = user;
			// event.action = this.typesName[event.type].desc;

			// state.events.unshift(event);
			//

			// Add/update the "calls" list if needed
			switch (event?.type) {
			case 3: // CALL_JOIN
				if (event.call_party_type === 1) { // CALLER
					// Add to incoming calls
					// TODO
				} else { // OPERATOR (or future RESPONDER)
					// Stop ringing
					// TODO
				}
				break;
			case 4: // CALL_LEAVE - single user
				if (event.call_party_type === 1) { // CALLER
					// Remove from incoming calls
					// TODO
				} else { // OPERATOR (or future RESPONDER)
					// Add to "on hold"
					// TODO
				}
				break;
			case 5: // CALL_LEAVE_ALL
			case 53: // CALL_LEAVE_DURESS
			case 54: // CALL_FAIL
				// Remove from call list
			}

			if (event && ((event.type === 3 && event.call_party_type === 1) || (event.type === 50 && event.call_party_type === null))) {
				state.lastEvent = [];
				state.lastEvent.push({ event: event, user: user });
			}

			if (event.task_location_status_id) {
				for (const i in state.locations) {
					if (state.locations[i].id === event.location_id) {
						state.locations[i].status = event.task_location_status_id;
						break;
					}
				}
			}
		},
	},
	actions: {
		async loadLocations ({ commit }) {
			commit('LOAD_LOCATIONS', await getLocationsForMap());
		},
		async loadEvents ({ commit }, filters) {
			commit('LOAD_EVENTS', await getEvents(filters));
		},
		async makeAsRead ({ commit }, obj) {
			await readedNotification(obj);
		},
		async loadEventDetails ({ commit }, correlationId) {
			commit('LOAD_EVENT_DETAILS', await getEventDetails(correlationId));
		},
	},
};
