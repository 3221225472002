import Repository from '@/repositories/RepositoryFactory';

const User = Repository.get('client', 'user');
const UserV1 = Repository.get('client', 'userV1');

export default {
	namespaced: true,
	state: {
		users: [],
		user: null,
		userChat: null,
		usersToSelect: [],
		usersSelected: [],
		usersToNotify: [],
		mapUser: [],
		externalUsersForSurvey: [],
		userRoles: [],
		getAppVersion: {},
		getProduct: {},
		logs: {
			data: [],
		},
	},

	mutations: {
		LOAD_USERS: (state, payload) => {
			state.users = payload.data;
		},
		LOAD_USER_BY_ID: (state, payload) => {
			state.user = payload.data[0];
		},
		LOAD_USER_CHAT_BY_ID: (state, payload) => {
			state.userChat = payload.data[0];
		},
		LOAD_USER_SELECTED: (state, payload) => {
			state.usersSelected = payload;
		},
		LOAD_USERS_TO_SELECT: (state, payload) => {
			state.usersToSelect = payload;
		},
		LOAD_USERS_TO_NOTIFY: (state, payload) => {
			state.usersToNotify = payload;
		},
		LOAD_EXTERNAL_USERS_FOR_SURVEY: (state, payload) => {
			state.externalUsersForSurvey = payload;
		},
		MAP_USER_DETAILS: (state, payload) => {
			state.mapUser = payload.data;
		},
		LOAD_USERS_SECURITY_ROLES: (state, payload) => {
			state.userRoles = payload;
		},
		LOAD_APP_VERSION: (state, payload) => {
			state.getAppVersion = payload;
		},
		LOAD_APP_PRODUCT: (state, payload) => {
			state.getProduct = payload;
		},
		SET_ACTIVITY_LOGS: (state, payload) => {
			state.logs = payload;
		},
	},
	actions: {
		async fetchActivityLogs ({ commit }, userId) {
			commit('SET_ACTIVITY_LOGS', await UserV1.getActivityLogs(userId)); // Use User repository's getActivityLogs method
		},
		async get ({ commit }) {
			commit('LOAD_USERS', await User.get());
		},

		async getAll ({ commit }, id) {
			commit('LOAD_USERS', await User.include(['phones,assignments.role']).where('trashed', 'with').where('organization_id', `${id}`).params({ type: 'simple' }).get());
		},
		async getAllForSecurity ({ commit }) {
			commit('LOAD_USERS', await User.include(['phones,assignments.role']).where('trashed', 'with').get());
		},
		async getUserRoles ({ commit }, item) {
			commit('LOAD_USERS_SECURITY_ROLES', await UserV1.getUserRoles(item));
		},
		async getLatestAppVersion ({ commit }) {
			commit('LOAD_APP_VERSION', await UserV1.getLatestAppVersion());
		},
		async getProduct ({ commit }) {
			commit('LOAD_APP_PRODUCT', await UserV1.getLatestAppVersion());
		},
		async mapUserDetails ({ commit }, id) {
			commit('MAP_USER_DETAILS', await UserV1.mapUserDetails(id));
		},
		async getUserDetails ({ commit }, object) {
			const user = new User({ id: object.id });
			commit('LOAD_USER_BY_ID', await User
				.include('phones', 'assignments.role', 'organization')
				.custom(user)
				.get());
		},
		async getUserDetailsChat ({ commit }, object) {
			const user = new User({ id: object.user_id });
			commit('LOAD_USER_CHAT_BY_ID', await User
				.include('phones', 'assignments.role', 'organization', '*')
				.custom(user)
				.get());
		},
		async getEmployeeById ({ commit }, id) {
			commit('LOAD_USER_BY_ID', await User.find(id));
		},

		async getSelectedUserDetails ({ commit }, id) {
			commit('LOAD_USER_SELECTED', await UserV1.getSelectedUserDetails(id));
		},

		async store ({ commit }, object) {
			const res = await new User(object);
			res.save();
			return res;
		},
		async storeV1 ({ commit }, object) {
			const res = await UserV1.store(object);
			return res;
		},

		// async update ({ commit }, object) {
		// 	const model = await User.include(['phones', 'addresses', 'assignments.role']).find(object.id);
		// 	model.given_name = object.given_name;
		// 	model.email = object.email;
		// 	model.surname = object.surname;
		// 	model.org_ident = object.org_ident;
		// 	// model.phone.number = object.phonenumber;
		// 	model.patch();
		// 	return model;
		// },

		async updateV1 ({ commit }, object) {
			const res = await UserV1.update(object);
			return res;
		},
		async setActiveUser ({ commit }, object) {
			const res = await UserV1.setActiveUser(object);
			return res;
		},
		async mailActivation ({ commit }, payload) {
			const id = payload.id;
			const object = {
				activation: 'email',
			};
			const res = await UserV1.mailActivation(object, id);
			return res;
		},
		async updatePassword ({ commit }, object) {
			const res = await UserV1.updatePassword(object);
			return res;
		},
		async deleteV1 ({ commit }, object) {
			await UserV1.delete(object);
		},
		// async clearUserAlarm ({ commit }, { id }) {
		// 	await UserV1.clearUserAlarm(id);
		// },
		async getExternalUsersByBranch ({ commit }, id) {
			commit('LOAD_EXTERNAL_USERS_FOR_SURVEY', await User.where('external', 'answer%23Survey').where('organization_id', `${id}`).include(['phones']).get());
		},

		async getUsersToSelectForSurvey ({ commit }, id) {
			commit('LOAD_USERS_TO_SELECT', await User.where('location', id).include('phones', 'assignments.role').get());
		},

		async getSurveyUsers ({ commit }, body) {
			commit('LOAD_USERS_TO_NOTIFY', await User.include(['phones', 'assignments.role']).where('location', body.branchId).where('organization_id', `${body.id}`).get());
		},
	},
};
