<template>
	<v-list>
		<v-list-item-group style="width: 240px;">
      <span v-for="chat in lastEvent" :key="chat.event.correlation_id" style="text-decoration: none;">
        <v-list-item v-if="isRinging" @click="stopSound(); removeEventFromList(chat.event.correlation_id)" :href="chat.event.url" target="_blank" :active-class="`primary white--text`" class="chat-item">
          <v-list-item-avatar>
            <v-icon class="blink" color="error" large dark>mdi-phone</v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-subtitle style="color: #1976D2; font-weight: bold">{{ byUser}} </v-list-item-subtitle>
			<v-list-item-subtitle style="color: #1976D2">{{ PhoneNumber(chat.user.phone_number) }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </span>
		</v-list-item-group>
	</v-list>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { AsYouType } from 'libphonenumber-js';
import ringSound from '@/sounds/ring_32kbs_1channel_with_silence.ogg';
import { Howl } from 'howler';

const ringer = new Howl({
	src: ringSound,
	loop: true,
	preload: true,
});

export default {
	beforeDestroy () {
		ringer.unload();
	},

	data () {
		return {
			dialog: false,
			chatRoomId: null,
			isRinging: false,
		};
	},

	computed: {
		...mapGetters(['pageHeight']),
		...mapState({
			events: state => state.dashboard.events,
			lastEvent: state => state.dashboard.lastEvent,
		}),
		byUser () {
			return this.lastEvent[0].user.given_name;
		},
	},

	watch: {
		lastEvent: {
			immediate: true,
			handler (newEvents) {
				if (eventIsNewIncomingCall(newEvents[0]?.event)) {
					this.isRinging = true;
					ringer.seek(0);
					ringer.play();
				} else if (eventIsCallAnsweredEndedOrFailed(newEvents[0]?.event)) {
					this.isRinging = false;
					ringer.stop();
				}
			},
		},
	},
	methods: {
		removeEventFromList (key) {
			var newLastEvent = this.lastEvent.filter(item => item.event.correlation_id !== key);
			this.$store.dispatch('dashboard/setLastEvent', newLastEvent);
		},

		PhoneNumber (val) {
			return new AsYouType().input(val);
		},
		stopSound () {
			ringer.stop();
		},
	},
};

function eventIsNewIncomingCall (event) {
	return event?.call_party_type === 1 && event?.type === 3;
}
function eventIsCallAnsweredEndedOrFailed (event) {
	return (event?.call_party_type !== 1 && event?.type === 3) || // answered
		(event?.call_party_type === 1 && event?.type === 4) || // one user left call
		event?.type === 5 || // all users left call
		event?.type === 54; // call failed to start
}
</script>

<style scoped>
.chat-item {
	box-shadow: 3px 3px 3px 3px rgba(0, 0, 0, 0.2);
	border-radius: 15px;
	padding: 3px 15px;
	margin-bottom: 10px;
	margin-right: 11px;
	transition: box-shadow 0.3s ease-in-out;
	background-color: white;
}

.chat-item:hover {
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.7);
}

.blink {
	animation: blinker 1s infinite;
}

@keyframes blinker {
	50% {
		opacity: 0;
	}
}
</style>
